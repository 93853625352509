
<template>
  <v-layout column>
    <v-layout justify-space-between>
      <span class="grey--text text--darken-2 headline">{{ $t('guests.transactions') }}</span>
    </v-layout>
    <transaction-list v-if="isPermissionActive('PAGINATE_TRANSACTIONS')"
      :listId="table"
      :list="transactions.list"
      :refreshColumnDecoration="refreshColumnDecoration"
      decorateColumnAction="transactions/decorateColumn"
      @refund="list(); $emit('refund')"
      class="mt-2"
      roomCharge
    />
    <v-alert v-else :value="true" color="warning" dark class="mt-3">{{ $t('guests.no_list_transactions_permission') }}</v-alert>
  </v-layout>
</template>

<script>
import permissionMixins from '@/mixins/Permission'
import paginationMixins from '@/mixins/Pagination'
import { transactionTypes } from '@/config'
import { mapState, mapGetters } from 'vuex'
import routeNames from '@/router/routes'
import Vue from 'vue'

export default {
  props: {
    guest: Object
  },
  data () {
    return {
      routeNames,
      table: 'GuestTransactions',
      refreshColumnDecoration: null
    }
  },
  computed: {
    ...mapState(['transactions', 'pagination', 'packages']),
    ...mapGetters({
      localCurrency: 'configuration/localCurrency'
    }),
    accountId () {
      return this.guest && this.guest.extendedAccount
        ? this.guest.extendedAccount.account.id
        : null
    },
    guestId () {
      return this.guest ? this.guest.id : null
    }
  },
  methods: {
    list () {
      if (this.isPermissionActive('PAGINATE_TRANSACTIONS')) {
        this.$store.commit('pagination/setLoading', { table: this.table, loading: true })
        this.$store.dispatch('transactions/list', {
          limit: this.tablePagination.pages.itemsPerPage,
          offset: (this.tablePagination.pages.page - 1) * this.tablePagination.pages.itemsPerPage,
          order: this.tablePagination.pages.descending ? 'ASC' : 'DESC',
          currencyIds: [this.localCurrency.id],
          type: transactionTypes.PAYMENT,
          accountIds: [this.accountId],
          guestId: this.guestId,
          linkedToOrder: false,
          decorate: true,
          save: true
        }).then((response) => {
          this.refreshColumnDecoration = new Date()
          this.updatePagination(response)
        })
      }
    }
  },
  created () {
    this.setItemsPerPageOptions({ value: [10, 25, 50], table: this.table })
    this.list()
  },
  watch: {
    guest () {
      this.list()
    }
  },
  components: {
    TransactionList: () => import('@/components/view-components/TransactionList.vue')
  },
  mixins: [
    paginationMixins,
    permissionMixins
  ],
  name: 'GuestOrders'
}
</script>
